import React from "react";

const NotFound = () =>{
    return (
        <div style={{"textAlign": "center"}}>
            Not Found
        </div>
    )
}

export default NotFound;